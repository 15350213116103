<script setup>
   import {
      CalendarIcon,
      ListBulletIcon,
      PlusCircleIcon,
      QuestionMarkCircleIcon,
   } from '@heroicons/vue/24/outline/index.js';
   const route = useRoute();
   const { t } = useI18n();
   const isActiveRoute = (routeName) => {
      return routeName.startsWith(route.name);
   };

   const createNewAvailability = () => {
      useTherapistInfoStore().setReloadValue(null);
      showCreateAvailabilityForm.value = true;
   };

   const afterSuccess = () => {
      showCreateAvailabilityForm.value = false;
      if (route.name !== 'therapist-custom-availability') {
         navigateTo('/therapist/custom-availability');
      } else {
         emit('reload', 1);
         reload.value = !reload.value;
      }
   };
   const showCreateAvailabilityForm = ref(false);
   const isCalendarPage = computed(() => route.name === 'therapist-calendar');
</script>

<template>
   <div
      class="sm:bg-white rounded sm:p-2 flex justify-between sm:gap-3 w-auto flex-col items-stretch gap-3"
      :class="isCalendarPage ? 'md:flex-row sm:items-end' : 'sm:flex-row'"
   >
      <div class="flex sm:gap-3 grow w-full flex-col sm:flex-row">
         <BaseButton
            :prepend-icon="ListBulletIcon"
            :variant="!isActiveRoute('therapist-calendar') ? '' : 'plain'"
            :color="
               !isActiveRoute('therapist-calendar') ? 'primary-500' : 'gray-200'
            "
            :to="{ name: 'therapist-schedule' }"
         >
            <span class="text-xs sm:text-sm"> Manage Availability </span>
         </BaseButton>
         <BaseButton
            :prepend-icon="CalendarIcon"
            :variant="isActiveRoute('therapist-calendar') ? '' : 'plain'"
            :color="
               isActiveRoute('therapist-calendar') ? 'primary-500' : 'gray-200'
            "
            :to="{ name: 'therapist-calendar' }"
         >
            <span class="text-xs sm:text-sm"> Calendar View </span>
         </BaseButton>
      </div>
      <div class="flex gap-3 flex-col sm:flex-row">
         <BaseButton
            v-if="isCalendarPage"
            size="sm"
            @click.prevent="createNewAvailability"
            class="grow sm:grow-0"
            color="primary-500"
            :prepend-icon="PlusCircleIcon"
         >
            <div class="text-nowrap">Add Custom Availability</div>
         </BaseButton>
         <BaseMenu location-class="end-0" block>
            <BaseButton
               class="w-full"
               variant="outlined"
               color="primary-500"
               size="sm"
               :prepend-icon="QuestionMarkCircleIcon"
            >
               <div class="text-nowrap">Need Help</div>
            </BaseButton>
            <template #content>
               <p class="text-sm">
                  <span class="font-semibold">
                     Need Help with Managing your Availability or Bookings?
                  </span>
                  <span class="text-xs">
                     Contact our support admin at
                     <a
                        :href="`mailto:customercare@talktime.ae`"
                        class="hover:underline text-primary-500 font-medium"
                     >
                        customercare@talktime.ae
                     </a>
                     or call at
                     <a
                        :href="`tel:${t('contactInfo.phone')}`"
                        class="hover:underline text-primary-500 font-medium"
                     >
                        {{ t('contactInfo.phone') }}
                     </a>
                  </span>
               </p>
            </template>
         </BaseMenu>
      </div>
   </div>
   <template v-if="!isActiveRoute('therapist-calendar')">
      <div class="flex items-center justify-between mt-6">
         <p class="font-medium mb-2">Therapist Hours</p>
         <div v-if="isActiveRoute('therapist-custom-availability')">
            <BaseButton
               @click.prevent="createNewAvailability"
               color="primary-500"
               class="sm:hidden"
               size="sm"
               :prepend-icon="PlusCircleIcon"
               style="padding: 5px"
            >
            </BaseButton>
            <BaseButton
               size="sm"
               class="hidden sm:flex"
               @click.prevent="createNewAvailability"
               color="primary-500"
               :prepend-icon="PlusCircleIcon"
            >
               Add Custom Availability
            </BaseButton>
         </div>
      </div>
      <p class="text-xs text-gray-200 mb-6 mt-3">
         Set your weekly availability to determine when patients can book
         appointments with you.
      </p>
   </template>

   <slot></slot>

   <BaseDialog
      v-model:open-dialog="showCreateAvailabilityForm"
      :title="$t('sessions.addNewCustomAvailability')"
   >
      <CalendarNewAvailability
         @success="afterSuccess"
         @close="showCreateAvailabilityForm = false"
      >
      </CalendarNewAvailability>
   </BaseDialog>
</template>
